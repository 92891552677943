import { BASE_SERVICE } from '../BaseService'

const nameSpace = '/promo-code/'

export const PromoCodesService = {
  ...BASE_SERVICE,
  create (payload) {
    return this.post(`${nameSpace}`, payload)
  },
  index () {
    return this.get(`${nameSpace}`)
  },
  // show (id) {
  //   return this.get(`${nameSpace}${id}`)
  // },
  edit (id, payload) {
    return this.put(`${nameSpace}${id}`, payload)
  },
  // searchCategory (id, payload) {
  //   return this.get(`${nameSpace}search/${id}?name=${payload}`)
  // },
  destroy (id) {
    return this.delete(`${nameSpace}${id}`)
  }
}
