import { BASE_SERVICE } from '../BaseService'

const nameSpace = '/billing-plan/'

export const BillingPlansService = {
  ...BASE_SERVICE,
  // create (payload) {
  //   return this.post(`${nameSpace}`, payload)
  // },
  index () {
    return this.get(`${nameSpace}`)
  }
  // show (id) {
  //   return this.get(`${nameSpace}/${id}`)
  // },
  // edit (id, payload) {
  //   return this.put(`${nameSpace}/${id}`, payload)
  // },
  // destroy (id) {
  //   return this.delete(`${nameSpace}/${id}`)
  // }
}
