import { BASE_SERVICE } from '../BaseService'

const nameSpace = '/discount-schedule/'

export const DiscountsService = {
  ...BASE_SERVICE,
  create (id, payload) {
    return this.post(`${nameSpace}${id}`, payload)
  },
  index (id, day) {
    return this.get(`${nameSpace}day/${id}?day=${day}`)
  },
  show (id, day) {
    return this.get(`${nameSpace}${id}?day=${day}`)
  },
  edit (id, payload) {
    return this.post(`${nameSpace}batch/${id}`, payload)
  }
  // postImage (id, payload) {
  //   return this.post(`${nameSpace}image/${id}`, payload)
  // },
  // editState (id, payload) {
  //   return this.put(`${nameSpace}state/${id}`, payload)
  // },
  // searchBar (payload) {
  //   return this.get(`${nameSpace}search/?name=${payload}`)
  // }
  // destroy (id) {
  //   return this.delete(`${nameSpace}/${id}`)
  // }
}
