export default {
  inserted: function (el, binding, vnode) {
    // console.log(binding, vnode)
    let UserPermisions = []
    const [ ModuleFilter ] = vnode.context.$store.state.authModule.data.user.modules.filter((v) => v.module === vnode.context.$route.meta.module[0])
    const { permissions } = ModuleFilter
    if (permissions.CREATE === 'GRANTED') { UserPermisions = [...UserPermisions, 'CREATE'] }
    if (permissions.DELETE === 'GRANTED') { UserPermisions = [...UserPermisions, 'DELETE'] }
    if (permissions.EDIT === 'GRANTED') { UserPermisions = [...UserPermisions, 'EDIT'] }
    if (permissions.VIEW === 'GRANTED') { UserPermisions = [...UserPermisions, 'VIEW'] }
    if (!UserPermisions.some(r => binding.value.includes(r))) {
      vnode.elm.parentElement.removeChild(vnode.elm)
    }
    // let fullPermissions = []
    // const RolFilter = vnode.context.$store.state.authModule.user.roles.filter((v) => v.description === vnode.context.$route.meta.rolePermission[0])
    // const PermFilter = RolFilter.map((v) => { return v.permissions })
    // for (let i = 0; i < PermFilter.length; i++) { fullPermissions.push(...PermFilter[i]) }
    // fullPermissions = fullPermissions.map((v) => { return v.name })
    // fullPermissions = fullPermissions.filter((item, index) => { return fullPermissions.indexOf(item) === index })
    // if (!fullPermissions.some(r => binding.value.includes(r))) {
    //   vnode.elm.parentElement.removeChild(vnode.elm)
    // }
  }
}
