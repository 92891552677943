import { BASE_SERVICE } from '../BaseService'

const nameSpace = '/bar/menu/report'

export const MenuReportService = {
  ...BASE_SERVICE,
  showDS (payload) {
    return this.get(`${nameSpace}?dateStart=${payload.dateStart}&dateEnd=${payload.dateEnd}`)
  },
  showDSI (payload1) {
    return this.get(`${nameSpace}?dateStart=${payload1.dateStart}&dateEnd=${payload1.dateEnd}&barIds=${payload1.barIds}`)
  }
}
