import Vue from 'vue'
import ZbRoleDirective from './zb-role-directive/index'
import ZbPermissionsDirective from './zb-permissions-directive/index'
import VueTinySlider from 'vue-tiny-slider'
import ZbMapboxComponent from './zb-mapbox-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

// Register it globally
Vue.component('validation-observer', ValidationObserver)
Vue.component('validation-provider', ValidationProvider)
Vue.component('zb-tiny-slider', VueTinySlider)
Vue.component('zb-mapbox-component', ZbMapboxComponent)
Vue.directive('permissions', ZbPermissionsDirective)
Vue.directive('role-permission', ZbRoleDirective)
