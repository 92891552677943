import { BASE_SERVICE } from '../BaseService'
import { buildFormData } from '../ZbBuildFormData.service'

const nameSpace = '/promo-video'

export const AppControlService = {
  ...BASE_SERVICE,
  create (payload) {
    const auxFormData = buildFormData(payload)
    return this.post(`${nameSpace}/`, auxFormData)
  },
  index () {
    return this.get(`${nameSpace}/`)
  },
  // show (id) {
  //   return this.get(`${nameSpace}/${id}`)
  // },
  edit (id, payload) {
    const auxFormData = buildFormData(payload)
    return this.put(`${nameSpace}/update/${id}`, auxFormData)
  },
  state (id, payload) {
    return this.put(`${nameSpace}/availability/${id}`, payload)
  },
  order (payload) {
    return this.put(`${nameSpace}/order/`, payload)
  },
  destroy (id) {
    return this.delete(`${nameSpace}/availability/${id}`)
  }
}
