import Vue from 'vue'
import axios from 'axios'
import { ApiInstance } from './v2/zoundbar.api'
import { VbApiInstance } from './v3/videobrand.api'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)
Vue.prototype.ZBInstance = ApiInstance
Vue.prototype.VBInstance = VbApiInstance
export default new VueAxios()
