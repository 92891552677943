import { BASE_SERVICE } from '../BaseService'

const nameSpace = '/bar/team/'

export const BarMembersService = {
  ...BASE_SERVICE,
  create (id, payload) {
    return this.post(`${nameSpace}${id}`, payload)
  },
  index (id) {
    return this.get(`${nameSpace}${id}`)
  },
  show (id) {
    return this.get(`${nameSpace}member/${id}`)
  },
  edit (id, payload) {
    return this.put(`${nameSpace}member/${id}`, payload)
  },
  editState (id, payload) {
    return this.put(`${nameSpace}state/${id}`, payload)
  },
  changePassword (id, payload) {
    return this.put(`${nameSpace}password/${id}`, payload)
  }
}
