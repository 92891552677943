import Vue from 'vue'
import Vuex from 'vuex'
import uiModule from './ui'
import authModule from './auth'
import langModule from './lang'
import settingsModule from './settings'
import companiesModule from './companies'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    uiModule,
    authModule,
    langModule,
    settingsModule,
    companiesModule
  }
})
