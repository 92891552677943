import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'
import { ZbAdminRouting } from './ZbAdminRouting'
// import jwtdecode from 'jwt-decode'
import { ServiceFactory } from '@/services/ServiceFactory'
import Cookies from 'js-cookie'
const _authService = ServiceFactory.get('AuthService')

Vue.use(VueRouter)

const loginToAdmin = (to, from, next) => {
  const authUser = store.state.authModule.data
  if (authUser && authUser.user.token) {
    next()
    return
  }
  next({ name: 'login' })
}

const adminToLogin = (to, from, next) => {
  const authUser = store.state.authModule.data
  if (authUser && authUser.user.token) {
    next({ name: 'admin' })
    return
  }
  next()
}

const routes = [
  {
    path: '*',
    redirect: '/login'
  },
  {
    path: '/admin',
    name: 'admin',
    redirect: 'admin/companies',
    children: ZbAdminRouting,
    beforeEnter: loginToAdmin,
    component: () => import('@/views/ZbAdmin.vue')
  },
  {
    path: '/login',
    name: 'login',
    beforeEnter: adminToLogin,
    component: () => import('@/views/ZbLogin.vue')
  },
  {
    path: '/resetpassword',
    name: 'resetPassword',
    beforeEnter: adminToLogin,
    component: () => import('@/views/ZbResetPassword')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.name !== 'login' && to.name !== 'resetPassword') {
    try {
      const data = await _authService.loginSettings()
      // console.log(data)
      store.state.authModule.data.user.modules = data.modules
      const authUser = data
      const { module } = to.meta
      if (authUser) {
        const authModules = authUser.modules.map((v) => { return v.module })
        if (authModules.includes(module[0])) {
          next()
          // console.log('deja pasar')
        } else {
          // console.log('mo deja pasar')
          next({ name: 'companies' })
        }
      } else {
        next({ name: 'login' })
      }
    } catch (error) {
      // if error on getting settings, send to login
      // console.log(store)
      // next({ name: 'login' })
      _authService.logOutSubmit()
        .then((resp) => {
          store.state.status = {}
          store.state.authModule.data = null
          Cookies.remove('authUser')
          // this.removeCredentials()
          return Promise.resolve()
        })
        .then(() => {
          // this.$router.push({ name: 'login' })
          next({ name: 'login' })
        })
        .catch((err) => {
          console.log(err)
          // this.$notify({
          //   type: 'error',
          //   text: err.msg
          // })
        })
    }
  } else if (to.name === 'resetPassword' && to.name !== 'login') {
    next()
  } else {
    next()
  }
})

export default router
