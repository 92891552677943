import { BASE_SERVICE } from '../BaseService'

const nameSpace = '/ads-banner/'

export const AdsBannerService = {
  ...BASE_SERVICE,
  getAdsBanner () {
    return this.get(`${nameSpace}`)
  },
  getAdsBannerCity (cityId) {
    return this.get(`${nameSpace}${cityId}`)
  },
  newAdBanner (postData) {
    return this.post(`${nameSpace}?title=${postData.title}&description=${postData.description}&externalLink=${postData.externalLink}&order=${postData.order}&typeRedirect=${postData.typeRedirect}&cityId=${postData.cityId}`)
  },
  editAdBanner (payload) {
    return this.put(`${nameSpace}${payload.id}`, payload)
  },
  editImgBanner (id, payload, type) {
    return this.put(`${nameSpace}image/${id}?type=${type}`, payload)
  },
  deleteAdBanner (id) {
    return this.delete(`${nameSpace}${id}`)
  }
}
