import Vue from 'vue'
import VueSweetalert2 from 'vue-sweetalert2'

const optionsSweetAlert = {
  confirmButtonColor: 'var(--primary-color)',
  cancelButtonColor: 'var(--accent-2-color)',
  background: '#FFF'
}

Vue.use(VueSweetalert2, optionsSweetAlert)

export default VueSweetalert2
