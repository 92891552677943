import { BASE_SERVICE } from '../BaseService'

const nameSpace = '/bar/security-measures/'

export const SecurityMeasuresService = {
  ...BASE_SERVICE,
  // create (payload) {
  //   return this.post(`${nameSpace}`, payload)
  // },
  index () {
    return this.get(`${nameSpace}`)
  },
  show (id) {
    return this.get(`${nameSpace}bar/${id}`)
  },
  editBarMeasure (id, payload) {
    return this.put(`${nameSpace}bar/${id}`, payload)
  }
  // edit (id, payload) {
  //   return this.put(`${nameSpace}/${id}`, payload)
  // }
  // destroy (id) {
  //   return this.delete(`${nameSpace}/${id}`)
  // }
}
