import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader
import Vue from 'vue'
import Vuetify, {
  VAlert,
  VApp,
  VAppBar,
  VAppBarNavIcon,
  VAvatar,
  VBadge,
  VBtn,
  VCard,
  VCardSubtitle,
  VCardTitle,
  VCardText,
  VCardActions,
  VCheckbox,
  VChip,
  VCombobox,
  VDataTable,
  VDialog,
  VDivider,
  VFileInput,
  VForm,
  VIcon,
  VImg,
  VList,
  VListGroup,
  VListItem,
  VListItemContent,
  VListItemGroup,
  VListItemTitle,
  VListItemSubtitle,
  VListItemIcon,
  VListItemAvatar,
  VListItemAction,
  VMenu,
  VNavigationDrawer,
  VPagination,
  VProgressCircular,
  VRadioGroup,
  VRadio,
  VRating,
  VResponsive,
  VSpacer,
  VSwitch,
  VSelect,
  VSlider,
  VSubheader,
  VToolbarTitle,
  VToolbar,
  VTabs,
  VTab,
  VTabItem,
  VTabsItems,
  VTabsSlider,
  VTextField,
  VTooltip,
  VWindow,
  VWindowItem,
  VDatePicker,
  VTextarea,
  VCalendar,
  VSheet,
  VSlideGroup,
  VSlideItem,
  VRow,
  VEditDialog,
  VSimpleTable,
  VCol,
  VAutocomplete,
  VContainer,
  VTimePicker,
  VProgressLinear,
  VLayout,
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelHeader,
  VExpansionPanelContent,
  VCarousel,
  VCarouselItem,
  VHover,
  VFlex,
  VStepper,
  VStepperHeader,
  VStepperStep,
  VStepperItems,
  VStepperContent,
  VTimeline,
  VTimelineItem
} from 'vuetify/lib'
import { Ripple, Resize, Scroll } from 'vuetify/lib/directives'
import es from 'vuetify/es5/locale/es'

import theme from './theme.vuetify'
import icons from './icons.vuetify'

Vue.use(Vuetify, {
  components: {
    VAlert,
    VApp,
    VAppBar,
    VAppBarNavIcon,
    VAvatar,
    VBadge,
    VBtn,
    VCard,
    VCardSubtitle,
    VCardTitle,
    VCardText,
    VCheckbox,
    VChip,
    VCardActions,
    VCombobox,
    VDataTable,
    VDialog,
    VDivider,
    VFileInput,
    VForm,
    VIcon,
    VImg,
    VList,
    VListGroup,
    VListItem,
    VListItemContent,
    VListItemGroup,
    VListItemTitle,
    VListItemSubtitle,
    VListItemIcon,
    VListItemAvatar,
    VListItemAction,
    VMenu,
    VNavigationDrawer,
    VPagination,
    VProgressCircular,
    VRating,
    VToolbarTitle,
    VSpacer,
    VSlider,
    VToolbar,
    VSwitch,
    VSelect,
    VSubheader,
    VRadioGroup,
    VRadio,
    VResponsive,
    VTabs,
    VTab,
    VTabItem,
    VTabsItems,
    VTabsSlider,
    VTextField,
    VTooltip,
    VWindow,
    VWindowItem,
    VDatePicker,
    VTextarea,
    VCalendar,
    VSheet,
    VSlideGroup,
    VSlideItem,
    VRow,
    VEditDialog,
    VSimpleTable,
    VCol,
    VAutocomplete,
    VContainer,
    VTimePicker,
    VProgressLinear,
    VLayout,
    VExpansionPanels,
    VExpansionPanel,
    VExpansionPanelHeader,
    VExpansionPanelContent,
    VCarousel,
    VCarouselItem,
    VHover,
    VFlex,
    VStepper,
    VStepperHeader,
    VStepperStep,
    VStepperItems,
    VStepperContent,
    VTimeline,
    VTimelineItem
  },
  directives: {
    Ripple,
    Resize,
    Scroll
  }
})

export default new Vuetify({
  theme,
  lang: {
    locales: { es },
    current: localStorage.getItem('currentLang') || 'es'
  },
  icons
})
