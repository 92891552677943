import { BASE_SERVICE } from '../BaseService'

const nameSpace = '/url-counter/'

export const UrlCounterService = {
  ...BASE_SERVICE,
  // create (id, payload) {
  //   return this.post(`${nameSpace}${id}`, payload)
  // },
  index () {
    return this.get(`${nameSpace}`)
  },
  urlDetail (url) {
    return this.get(`${nameSpace}service-from?bannerUrl=${url}`)
  },
  urlShortener (payload) {
    return this.get(`${nameSpace}url-shortener?bannerUrl=${payload.bannerUrl}&serviceFrom=${payload.serviceFrom}`)
  },
  urlStats (dateStart, dateEnd, url) {
    return this.get(`${nameSpace}statistics?dateStart=${dateStart}&dateEnd=${dateEnd}&url=${url}`)
  },
  servStats (dateStart, dateEnd, url, serv) {
    return this.get(`${nameSpace}service-from/statistics?dateStart=${dateStart}&dateEnd=${dateEnd}&url=${url}&serviceFrom=${serv}`)
  }
  // edit (id, payload) {
  //   return this.put(`${nameSpace}${id}`, payload)
  // },
  // postImage (id, payload) {
  //   return this.post(`${nameSpace}image/${id}`, payload)
  // },
  // editState (id, payload) {
  //   return this.put(`${nameSpace}state/${id}`, payload)
  // },
  // searchBar (payload) {
  //   return this.get(`${nameSpace}search/?name=${payload}`)
  // }
  // destroy (id) {
  //   return this.delete(`${nameSpace}/${id}`)
  // }
}
