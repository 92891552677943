<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 495.974 495.974" style="enable-background:new 0 0 495.974 495.974;" xml:space="preserve">
    <g fill="currentColor" id="XMLID_241_">
      <path id="XMLID_246_" d="M404.918,269.978L335.94,118.864c-3.491-7.66-11.088-12.096-19.004-12.03   c-0.019,0.046-0.019,0.089-0.031,0.135c-3.042,32.534-20.478,114.299-41.602,114.299c-21.106,0-38.542-81.759-41.597-114.299   c0-0.039,0-0.089,0-0.129c-7.916-0.071-15.513,4.364-19.022,12.024l-30.816,67.512c-1.328-0.912-2.864-1.573-4.591-1.573h-61.381   c-4.573,0-8.273,3.7-8.273,8.273v2.077c-11.315,0.138-20.441,9.299-20.441,20.639c0,11.337,9.127,20.5,20.441,20.636v59.343   c0,4.576,3.7,8.276,8.273,8.276h61.381c4.561,0,8.272-3.7,8.272-8.276v-59.294h5.624c8.113,0,15.47-4.727,18.831-12.096   l4.671-10.246l-0.068,257.018c0,13.713,11.119,24.821,24.824,24.821c13.724,0,24.824-11.108,24.824-24.821v-147.93h18.1v147.93   c0,13.713,11.118,24.821,24.824,24.821c13.718,0,24.824-11.108,24.824-24.821l-0.049-257.018l33.342,73.023   c3.455,7.611,10.971,12.103,18.826,12.103c2.858,0,5.783-0.605,8.58-1.875C405.077,292.646,409.669,280.368,404.918,269.978z    M171.003,287.498h-44.83v-86.146h44.83V287.498z"/>
      <path id="XMLID_245_" d="M299.628,145.588c13.946-13.955,13.946-36.581,0-50.536c-1.954-1.948-5.089-1.948-7.049,0l-17.277,17.268   l-17.259-17.268c-1.936-1.948-5.107-1.948-7.043,0c-13.964,13.955-13.964,36.581,0,50.536c1.937,1.945,5.107,1.945,7.043,0   l17.259-17.271l17.277,17.271C294.54,147.533,297.674,147.533,299.628,145.588z"/>
      <path id="XMLID_244_" d="M275.303,96.732c15.033,0,28.266-6.973,37.141-17.703c6.914-8.365,11.247-18.952,11.247-30.66   C323.691,21.648,302.038,0,275.303,0c-26.693,0-48.352,21.648-48.352,48.369c0,11.702,4.314,22.295,11.248,30.66   C247.055,89.75,260.306,96.732,275.303,96.732z"/>
      <path id="XMLID_243_" d="M275.303,147.051c-4.554,0-8.273,3.7-8.273,8.272c0,4.572,3.719,8.275,8.273,8.275   c4.591,0,8.278-3.703,8.278-8.275C283.581,150.751,279.894,147.051,275.303,147.051z"/>
      <path id="XMLID_242_" d="M275.303,180.147c-4.554,0-8.273,3.699-8.273,8.275c0,4.573,3.719,8.272,8.273,8.272   c4.591,0,8.278-3.699,8.278-8.272C283.581,183.847,279.894,180.147,275.303,180.147z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'iconWaiter'
}
</script>
