import { BASE_SERVICE } from '../BaseService'

const nameSpace = '/country/'

export const CitiesService = {
  ...BASE_SERVICE,
  // create (payload) {
  //   return this.post(`${nameSpace}`, payload)
  // },
  index (country) {
    return this.get(`${nameSpace}${country}`)
  },
  show (id) {
    return this.get(`${nameSpace}${id}`)
  }
  // edit (id, payload) {
  //   return this.put(`${nameSpace}/${id}`, payload)
  // },
  // destroy (id) {
  //   return this.delete(`${nameSpace}/${id}`)
  // }
}
