import { AuthService } from './auth/auth.service'
import { ModulesService } from './modules/modules.service'
import { BillingPlansService } from './billing-plan/billingPlans.service'
import { BusinessTypesService } from './business-type/businessTypes.service'
import { BarsService } from './bars/bars.service'
import { SecurityMeasuresService } from './security-measures/securityMeasures.service'
import { BarMembersService } from './bar-members/bar-members.service'
import { ProductsService } from './products/products.service'
import { BarAreasService } from './bar-areas/bar-areas.service'
import { ProductCategoriesService } from './product-categories/product-categories.service'
import { CitiesService } from './cities/cities.service'
import { MenuReportService } from './menu-report/report.service'
import { BookingsService } from './bookings/bookings.service'
import { TablesService } from './tables/tables.service'
import { DiscountsService } from './discounts/discounts.service'
import { BookingsReportService } from './bookings-report/bookings-report.service'
import { UsersReportService } from './users-report/user-report.service'
import { BillingReportService } from './billing-report/billing-report.service'
import { AdsBannerService } from './ads-banner/ads-banner.service'
import { NotificationsService } from './notifications/notifications.service'
import { PromoCodesService } from './promo-code/promo-code.service'
import { ZonesService } from './zones/zones.service'
import { SpecialConditionsService } from './special-conditions/specialConditions.service'
import { UrlCounterService } from './url-counter/urlCounter.service'
import { ClientSearchService } from './client-search/client-search.service'
import { UsersService } from './users/users.services'
import { MetricsService } from './metrics/metrics.service'
import { DiscountCalendarService } from './discount-calendar/discountCalendar.service'
import { BarMetricsService } from './bar-metrics/barMetrics.service'
import { FeedbackService } from './feedback/feedback.service'
import { BarVideoService } from './bar-video/barVideo.service'
import { AppControlService } from './app-control/appControl.service'

const Services = {
  AuthService,
  BillingPlansService,
  BusinessTypesService,
  ModulesService,
  BarsService,
  SecurityMeasuresService,
  BarMembersService,
  ProductsService,
  BarAreasService,
  ProductCategoriesService,
  CitiesService,
  MenuReportService,
  BookingsService,
  TablesService,
  DiscountsService,
  BookingsReportService,
  UsersReportService,
  BillingReportService,
  AdsBannerService,
  NotificationsService,
  PromoCodesService,
  ZonesService,
  SpecialConditionsService,
  UrlCounterService,
  ClientSearchService,
  UsersService,
  MetricsService,
  DiscountCalendarService,
  BarMetricsService,
  FeedbackService,
  BarVideoService,
  AppControlService
}

export const ServiceFactory = {
  get: name => Services[name]
}
