import Vue from 'vue'
import { extend } from 'vee-validate'
import { required, max, email, between, numeric } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: 'El campo es requerido'
  // message: '{_field_} es requerido'
})

extend('max', {
  ...max,
  message: 'El campo no puede tener más de {length} caracteres'
})

extend('between', {
  ...between,
  message: 'El campo solo acepta un rango de decimales de {min} a {max}'
})

extend('numeric', {
  ...numeric,
  message: 'El campo solo acepta numeros'
})

extend('decimal', {
  validate: (value, { decimals = '*', separator = '.' } = {}) => {
    if (value === null || value === undefined || value === '') {
      return {
        valid: false
      }
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value)
      }
    }
    const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`
    const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`)
    return {
      valid: regex.test(value)
    }
  },
  message: 'El campo debe contener solo numeros enteros ó decimales'
})

extend('email', {
  ...email,
  message: 'El campo de email debe ser valido'
})

// extend('url', {
//   validate(value: string | null | undefined): boolean {
//     if (value) {
//       return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(value);
//     }

//     return false;
//   },
//   message: 'This value must be a valid URL',
// })

extend('url', {
  validate (value) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator
    return pattern.test(value)
  },
  message: 'El campo debe ser un link valido.'
})

// extend('924', {
//   // ...email,
//   message: 'El alto de la imágen debe ser de 924'
// })

// extend('1640', {
//   // ...email,
//   message: 'El ancho de la imágen debe ser de 1640'
// })

Vue.use({ extend }, { inject: false })

export default { extend }
