import { BASE_SERVICE } from '../BaseService'

const nameSpace = '/users/'

export const UsersService = {
  ...BASE_SERVICE,
  index () {
    return this.get(`${nameSpace}`)
  },
  create (payload) {
    return this.post(`${nameSpace}`, payload)
  },
  // show (id) {
  //   return this.get(`${nameSpace}/${id}`)
  // },
  edit (id, payload) {
    return this.put(`${nameSpace}${id}`, payload)
  },
  updatePassword (id, payload) {
    return this.put(`${nameSpace}password/${id}`, payload)
  },
  destroy (id) {
    return this.delete(`${nameSpace}state/${id}`)
  },
  enable (id) {
    return this.put(`${nameSpace}state/${id}`)
  }
}
