import { state } from './ui.state'
import { getters } from './ui.getters'
import { mutations } from './ui.mutations'

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
