// CustomIcons
import iconTvmusic from '@/icons/iconTvmusic'
import iconListmusic from '@/icons/iconListmusic'
import iconSuggested from '@/icons/iconSuggested'
import iconTicket from '@/icons/iconTicket'
import iconPlus from '@/icons/iconPlus'
import iconRestrinctSong from '@/icons/iconRestrictSong'
import iconRestore from '@/icons/iconRestore'
import iconExpandWide from '@/icons/iconExpandWide'
import iconRectangle from '@/icons/iconRectangle'
import iconFileCertificate from '@/icons/iconFileCertificate'
import iconListVideo from '@/icons/iconListVideo'
import iconListVideoX from '@/icons/iconListVideoX'
import iconCheck from '@/icons/iconCheck'
import iconListCheck from '@/icons/iconListCheck'
import iconWaiter from '@/icons/iconWaiter'
import iconCertificate from '@/icons/iconCertificate'

const icons = {
  iconfont: 'fa',
  values: {
    iconTvmusic: {
      component: iconTvmusic,
      props: {
        name: 'iconTvmusic'
      }
    },
    listMusic: {
      component: iconListmusic,
      props: {
        name: 'iconListmusic'
      }
    },
    iconSuggested: {
      component: iconSuggested,
      props: {
        name: 'iconSuggested'
      }
    },
    iconTicket: {
      component: iconTicket,
      props: {
        name: 'iconTicket'
      }
    },
    iconPlus: {
      component: iconPlus,
      props: {
        name: 'iconPlus'
      }
    },
    iconRestrinctSong: {
      component: iconRestrinctSong,
      props: {
        name: 'iconRestrinctSong'
      }
    },
    iconRestore: {
      component: iconRestore,
      props: {
        name: 'iconRestore'
      }
    },
    iconExpandWide: {
      component: iconExpandWide,
      props: {
        name: 'iconExpandWide'
      }
    },
    iconRectangle: {
      component: iconRectangle,
      props: {
        name: 'iconRectangle'
      }
    },
    iconFileCertificate: {
      component: iconFileCertificate,
      props: {
        name: 'iconFileCertificate'
      }
    },
    iconListVideo: {
      component: iconListVideo,
      props: {
        name: 'iconListVideo'
      }
    },
    iconListVideoX: {
      component: iconListVideoX,
      props: {
        name: 'iconListVideoX'
      }
    },
    iconCheck: {
      component: iconCheck,
      props: {
        name: 'iconCheck'
      }
    },
    iconListCheck: {
      component: iconListCheck,
      props: {
        name: 'iconListCheck'
      }
    },
    iconWaiter: {
      component: iconWaiter,
      props: {
        name: 'iconWaiter'
      }
    },
    iconCertificate: {
      component: iconCertificate,
      props: {
        name: 'iconCertificate'
      }
    }
  }
}

export default icons
