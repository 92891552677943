const ZbCompaniesComponent = () => import('@/modules/zb-admin/companies')
const ZbCompaniesFormComponent = () => import('@/modules/zb-admin/companies/tab-companies/form-companies')
const ZbCompaniesTableComponent = () => import('@/modules/zb-admin/companies/tab-companies/table-companies')
const ZbCompaniesEditTabsComponent = () => import('@/modules/zb-admin/companies/tab-companies/table-companies/tabs-edit-companies')
const ZbCompaniesLocationComponent = () => import('@/modules/zb-admin/companies/tab-companies/table-companies/tabs-edit-companies/tab-location')
const ZbBarMembersFormComponent = () => import('@/modules/zb-admin/companies/tab-companies/table-companies/tabs-edit-companies/tab-owners/form-owners')
const ZbBarMembersTabComponent = () => import('@/modules/zb-admin/companies/tab-companies/table-companies/tabs-edit-companies/tab-owners')
const ZbBarProfileTabComponent = () => import('@/modules/zb-admin/companies/tab-companies/table-companies/tabs-edit-companies/tab-bar-profile')
const ZbBarDiscountsComponent = () => import('@/modules/zb-admin/companies/tab-companies/table-companies/tabs-edit-companies/tab-discounts')
const ZbBarDiscountsCalendarComponent = () => import('@/modules/zb-admin/companies/tab-companies/table-companies/tabs-edit-companies/tab-discounts-calendar')
const ZbBarImagesTabComponent = () => import('@/modules/zb-admin/companies/tab-companies/table-companies/tabs-edit-companies/tab-images')
const ZbBarScheduleTabComponent = () => import('@/modules/zb-admin/companies/tab-companies/table-companies/tabs-edit-companies/tab-schedule')
const ZbBarLocationTabComponent = () => import('@/modules/zb-admin/companies/tab-companies/table-companies/tabs-edit-companies/tab-location')
const ZbBarSecurityMeasuresTabComponent = () => import('@/modules/zb-admin/companies/tab-companies/table-companies/tabs-edit-companies/tab-security-measures')
const ZbBarBookingsComponent = () => import('@/modules/zb-admin/companies/tab-companies/table-companies/tabs-edit-companies/tab-bookings')
// const ZbBookingsActiveComponent = () => import('@/modules/zb-admin/companies/tab-companies/table-companies/tabs-edit-companies/tab-bookings/tab-bookings-active')
// const ZbBookingsCompletedComponent = () => import('@/modules/zb-admin/companies/tab-companies/table-companies/tabs-edit-companies/tab-bookings/tab-bookings-completed')
// const ZbBookingsCancelledComponent = () => import('@/modules/zb-admin/companies/tab-companies/table-companies/tabs-edit-companies/tab-bookings/tab-bookings-cancelled')
const ZbProductTabComponent = () => import('@/modules/zb-admin/companies/tab-companies/table-companies/tabs-edit-companies/tab-products')
const ZbBarAreaTabComponent = () => import('@/modules/zb-admin/companies/tab-companies/table-companies/tabs-edit-companies/tab-bar-area')
const ZbBarPremiumComponent = () => import('@/modules/zb-admin/companies/tab-companies/table-companies/tabs-edit-companies/tab-premium')
const ZbProductsComponent = () => import('@/modules/zb-admin/products')
const ZbBookingsChartComponent = () => import('@/modules/zb-admin/bookings-chart')
const ZbConsumersChartComponent = () => import('@/modules/zb-admin/consumers-chart')
const ZbBillingReportComponent = () => import('@/modules/zb-admin/billing-report')
const ZbAdsBannerComponent = () => import('@/modules/zb-admin/ads-banner')
const ZbNotificationsComponent = () => import('@/modules/zb-admin/notifications')
const ZbPromotionalCodeComponent = () => import('@/modules/zb-admin/promotional-code')
const ZbBookingsComponent = () => import('@/modules/zb-admin/bookings')
const ZbZonesComponent = () => import('@/modules/zb-admin/zones')
const ZbBusinessTypeComponent = () => import('@/modules/zb-admin/business-type')
const ZbBarSpecialCoditionsTabComponent = () => import('@/modules/zb-admin/companies/tab-companies/table-companies/tabs-edit-companies/tab-special-conditions')
const ZbBarStatsTabComponent = () => import('@/modules/zb-admin/companies/tab-companies/table-companies/tabs-edit-companies/tab-bar-stats')
const ZbUrlCounterComponent = () => import('@/modules/zb-admin/url-counter')
const ZbUrlTable = () => import('@/modules/zb-admin/url-counter/url-table')
const ZbUrlDetail = () => import('@/modules/zb-admin/url-counter/url-detail')
const ZbNotificationsTable = () => import('@/modules/zb-admin/notifications/notifications-table')
const ZbNotificationDetail = () => import('@/modules/zb-admin/notifications/notification-detail')
const ZbDashboardComponent = () => import('@/modules/zb-admin/dashboard')
// const ZbCompaniesLocationComponent = () => import('@/modules/zb-admin/companies/tab-companies/table-companies/tabs-edit-companies/tab-location')
// // Perfil de usuario
// const BmProfileMapComponent = () => import('@/modules/bm-admin/profile')
// const BmProfileFormComponent = () => import('@/modules/bm-admin/profile/tab-profile/form-profile')
// const BmTabProfileMapComponent = () => import('@/modules/bm-admin/profile/tab-profile')
// // Users & roles
const ZbUsersComponent = () => import('@/modules/zb-admin/users')
const ZbUsersTable = () => import('@/modules/zb-admin/users/table')
const ZbUsersForm = () => import('@/modules/zb-admin/users/form')
const ZbFeedbackComponent = () => import('@/modules/zb-admin/feedback')
const ZbFeedback = () => import('@/modules/zb-admin/feedback/table')
const ZbHistory = () => import('@/modules/zb-admin/feedback/history')
const RbPromoVideos = () => import('@/modules/zb-admin/promotional-video')
// const BmFormUserComponent = () => import('@/modules/bm-admin/users/tab-users/form-user')
// const BmTabUsersComponent = () => import('@/modules/bm-admin/users/tab-users')
// const BmTabRolesComponent = () => import('@/modules/bm-admin/users/tab-roles')
// const BmFormRolesComponent = () => import('@/modules/bm-admin/users/tab-roles/form-roles')

// // Personalización
// const BmCustomizeComponent = () => import('@/modules/bm-admin/customize')
// const BmCitiesFormComponent = () => import('@/modules/bm-admin/customize/tab-cities/form-cities')
// const BmTabCitiesComponent = () => import('@/modules/bm-admin/customize/tab-cities')
// const BmTabDepartmentsComponent = () => import('@/modules/bm-admin/customize/tab-departments')
// const BmDepartmentsFormComponent = () => import('@/modules/bm-admin/customize/tab-departments/form-departments')
const ZbReserbarBillingComponent = () => import('@/modules/zb-admin/billing-report/reserbar-billing')

// VideoBrand
const VbBillingComponent = () => import('@/modules/zb-admin/billing-report/videobrand-billing')
const VbProjectInfoComponent = () => import('@/modules/zb-admin/billing-report/videobrand-billing/project-info')
const VbProjectFormComponent = () => import('@/modules/zb-admin/billing-report/videobrand-billing/project-info/project-form')

export const ZbAdminRouting = [
  {
    meta: { icon: 'fas fa-home' },
    path: '/admin/location',
    name: 'location',
    component: ZbCompaniesLocationComponent
  },
  {
    meta: { icon: 'fas fa-building', module: ['BUSINESS'] },
    path: 'companies',
    name: 'companies',
    redirect: 'companies/companies-table',
    component: ZbCompaniesComponent,
    children: [
      {
        meta: { icon: 'fas fa-building', module: ['BUSINESS'] },
        path: 'companies-table',
        name: 'companies-table',
        components: {
          companies: ZbCompaniesTableComponent
        }
      },
      {
        meta: { icon: 'fas fa-clipboard', module: ['BUSINESS'] },
        path: 'companies-form',
        name: 'companies-form',
        components: {
          companies: ZbCompaniesFormComponent
        }
      },
      {
        meta: { icon: 'fas fa-building', module: ['BUSINESS'] },
        path: 'companies-tabs',
        name: 'companies-tabs',
        // redirect: 'companies-tabs/members',
        components: {
          companies: ZbCompaniesEditTabsComponent
        },
        children: [
          {
            meta: { icon: 'fas fa-building', module: ['BUSINESS'] },
            path: 'edit',
            name: 'edit',
            components: {
              profile: ZbBarProfileTabComponent,
              discounts: ZbBarDiscountsComponent,
              discounts_calendar: ZbBarDiscountsCalendarComponent,
              members: ZbBarMembersTabComponent,
              images: ZbBarImagesTabComponent,
              schedule: ZbBarScheduleTabComponent,
              location: ZbBarLocationTabComponent,
              security_measures: ZbBarSecurityMeasuresTabComponent,
              bookings: ZbBarBookingsComponent,
              products: ZbProductTabComponent,
              bar_area: ZbBarAreaTabComponent,
              special_conditions: ZbBarSpecialCoditionsTabComponent,
              stats: ZbBarStatsTabComponent,
              premium: ZbBarPremiumComponent
            }
            // children: [
            //   {
            //     path: 'booking',
            //     name: 'booking',
            //     components: {
            //       active: ZbBookingsActiveComponent,
            //       completed: ZbBookingsCompletedComponent,
            //       cancelled: ZbBookingsCancelledComponent
            //     }
            //   }
            // ]
          },
          {
            meta: { icon: 'fas fa-address-card', module: ['BUSINESS'] },
            path: 'member-form',
            name: 'member-form',
            components: {
              members: ZbBarMembersFormComponent
            }
          }
        ]
      }
    ]
  },
  {
    meta: { icon: 'fas fa-clipboard-list', module: ['BOOKINGS'] },
    path: 'bookings',
    name: 'bookings',
    component: ZbBookingsComponent
  },
  {
    meta: { icon: 'fas fa-boxes', module: ['CHARTS'] },
    path: 'products',
    name: 'products',
    component: ZbProductsComponent
  },
  {
    meta: { icon: 'fas fa-clipboard-list', module: ['CHARTS'] },
    path: 'bookings-chart',
    name: 'bookings-chart',
    component: ZbBookingsChartComponent
  },
  {
    meta: { icon: 'fas fa-users', module: ['CHARTS'] },
    path: 'consumers-chart',
    name: 'consumers-chart',
    component: ZbConsumersChartComponent
  },
  {
    meta: { icon: 'fas fa-file-invoice-dollar', module: ['BILLING'] },
    path: 'billing',
    name: 'billing',
    redirect: 'billing/reserbar',
    component: ZbBillingReportComponent,
    children: [
      {
        meta: { icon: 'fas fa-bell', module: ['BILLING'] },
        path: 'reserbar',
        name: 'reserbar',
        components: {
          'billing': ZbReserbarBillingComponent
        }
      },
      {
        meta: { icon: 'fas fa-bell', module: ['BILLING'] },
        path: 'videobrand',
        name: 'videobrand',
        components: {
          'billing': VbBillingComponent
        }
      },
      {
        meta: { icon: 'fas fa-bell', module: ['BILLING'] },
        path: 'info',
        name: 'info',
        components: {
          'billing': VbProjectInfoComponent
        }
      },
      {
        meta: { icon: 'fas fa-bell', module: ['BILLING'] },
        path: 'edit-project',
        name: 'edit-project',
        components: {
          'billing': VbProjectFormComponent
        }
      }
    ]
  },
  {
    meta: { icon: 'fas fa-ad', module: ['BUSINESS'] },
    path: 'ads-banner',
    name: 'ads-banner',
    component: ZbAdsBannerComponent
  },
  {
    meta: { icon: 'fas fa-bell', module: ['NOTIFICATIONS'] },
    path: 'notifications',
    name: 'notifications',
    redirect: 'notifications/table',
    component: ZbNotificationsComponent,
    children: [
      {
        meta: { icon: 'fas fa-bell', module: ['NOTIFICATIONS'] },
        path: 'table',
        name: 'notif-table',
        components: {
          'notifications': ZbNotificationsTable
        }
      },
      {
        meta: { icon: 'fas fa-bell', module: ['NOTIFICATIONS'] },
        path: 'detail',
        name: 'notif-detail',
        components: {
          'notifications': ZbNotificationDetail
        }
      }
    ]
  },
  {
    meta: { icon: 'fas fa-spell-check', module: ['BUSINESS'] },
    path: 'promotional-code',
    name: 'promotional-code',
    component: ZbPromotionalCodeComponent
  },
  {
    meta: { icon: 'fas fa-map', module: ['BUSINESS'] },
    path: 'zones',
    name: 'zones',
    component: ZbZonesComponent
  },
  {
    meta: { icon: 'fas fa-list', module: ['BUSINESS'] },
    path: 'business-type',
    name: 'business-type',
    component: ZbBusinessTypeComponent
  },
  {
    meta: { icon: 'fas fa-file-chart-line', module: ['CHARTS'] },
    path: 'url-counter',
    name: 'url-counter',
    redirect: 'url-counter/url-table',
    component: ZbUrlCounterComponent,
    children: [
      {
        meta: { icon: 'fas fa-link', module: ['CHARTS'] },
        path: 'url-table',
        name: 'url-table',
        components: {
          'url-counter': ZbUrlTable
        }
      },
      {
        meta: { icon: 'fas fa-link', module: ['CHARTS'] },
        path: 'url-detail',
        name: 'url-detail',
        components: {
          'url-counter': ZbUrlDetail
        }
      }
    ]
  },
  {
    meta: { icon: 'fas fa-user', module: ['MANAGEMENT'] },
    path: 'users',
    name: 'users',
    redirect: 'users/table',
    component: ZbUsersComponent,
    children: [
      {
        meta: { icon: 'fas fa-user', module: ['MANAGEMENT'] },
        path: 'table',
        name: 'users-table',
        components: {
          'users': ZbUsersTable
        }
      },
      {
        meta: { icon: 'fas fa-user', module: ['MANAGEMENT'] },
        path: 'form',
        name: 'users-form',
        components: {
          'users': ZbUsersForm
        }
      }
    ]
  },
  {
    meta: { icon: 'fas fa-chart-line', module: ['CHARTS'] },
    path: 'dashboard',
    name: 'dashboard',
    component: ZbDashboardComponent
  },
  {
    meta: { icon: 'fas fa-user', module: ['CHARTS'] },
    path: 'feedback',
    name: 'feedback',
    redirect: 'feedback/table',
    component: ZbFeedbackComponent,
    children: [
      {
        meta: { icon: 'fas fa-user', module: ['CHARTS'] },
        path: 'table',
        name: 'feedback-table',
        components: {
          'feedback': ZbFeedback
        }
      },
      {
        meta: { icon: 'fas fa-user', module: ['CHARTS'] },
        path: 'history',
        name: 'history',
        components: {
          'feedback': ZbHistory
        }
      }
    ]
  },
  {
    meta: { icon: 'fas fa-play', module: ['BUSINESS'] },
    path: 'promotional-video',
    name: 'promotional-video',
    component: RbPromoVideos
  }
]
