import { BASE_SERVICE } from '../BaseService'
import { buildFormData } from '../ZbBuildFormData.service'

const nameSpace = '/products/'

export const ProductsService = {
  ...BASE_SERVICE,
  create (payload) {
    const auxFormData = buildFormData(payload)
    return this.post(`${nameSpace}`, auxFormData)
  },
  index (id) {
    return this.get(`${nameSpace}bar/${id}`)
  },
  show (id) {
    return this.get(`${nameSpace}${id}`)
  },
  edit (id, payload) {
    const auxFormData = buildFormData(payload)
    // console.log(auxFormData)
    return this.put(`${nameSpace}${id}`, auxFormData)
  },
  deleteImage (id) {
    return this.delete(`${nameSpace}image/${id}`)
  },
  discount (id) {
    return this.put(`${nameSpace}discount/${id}`)
  },
  searchProduct (id, payload) {
    return this.get(`${nameSpace}search/${id}?name=${payload}`)
  },
  destroy (id) {
    return this.delete(`${nameSpace}${id}`)
  }
}
