<template>
  <v-app>
    <router-view></router-view>
    <notifications class="mr-5 mb-5" position="bottom right"/>
  </v-app>
</template>
<script>
export default {
  name: 'App'
}
</script>
<style lang="scss">
.theme--dark.v-application {
  font-family: 'Varela Round', 'sans-serif';
  background: var(--secondary-color) !important;
}
</style>
