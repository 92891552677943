import { BASE_SERVICE } from '../BaseService'

const nameSpace = '/metrics/'

export const MetricsService = {
  ...BASE_SERVICE,
  // create (payload) {
  //   return this.post(`${nameSpace}`, payload)
  // },
  range (dateStart, dateEnd, cityId) {
    return this.get(`${nameSpace}range?dateStart=${dateStart}&dateEnd=${dateEnd}&city_id=${cityId}`)
  },
  top (dateStart, dateEnd, cityId) {
    return this.get(`${nameSpace}top/business?dateStart=${dateStart}&dateEnd=${dateEnd}&city_id=${cityId}`)
  },
  bookingsMetrics (dateStart, dateEnd, cityId) {
    return this.get(`${nameSpace}average?dateStart=${dateStart}&dateEnd=${dateEnd}&city_id=${cityId}`)
  },
  growth (dateStart, dateEnd, cityId) {
    return this.get(`${nameSpace}growth?dateStart=${dateStart}&dateEnd=${dateEnd}&city_id=${cityId}`)
  }
  // show (id) {
  //   return this.get(`${nameSpace}/${id}`)
  // },
  // edit (id, payload) {
  //   return this.put(`${nameSpace}/${id}`, payload)
  // },
  // destroy (id) {
  //   return this.delete(`${nameSpace}/${id}`)
  // }
}
