import { BASE_SERVICE } from '../BaseService'

const nameSpace = '/client/'

export const ClientSearchService = {
  ...BASE_SERVICE,
  // create (payload) {
  //   return this.post(`${nameSpace}`, payload)
  // },
  search (name) {
    return this.get(`${nameSpace}search?name=${name}`)
  },
  searchUsers (name, page) {
    return this.get(`${nameSpace}search?name=${name}&type=history&page=${page}`)
  },
  feedbackFilter (businessIds, discount, dates, page) {
    var params = ''
    if (businessIds.length > 0) {
      params += '?business=' + businessIds + '&page=' + page
    }
    if (discount) {
      if (params === '') {
        params += '?discount=' + discount + '&page=' + page
      } else {
        params += '&discount=' + discount + '&page=' + page
      }
    }
    if (dates.length > 1) {
      if (params === '') {
        params += '?dateStart=' + dates[0] + '&dateEnd=' + dates[1] + '&page=' + page
      } else {
        params += '&dateStart=' + dates[0] + '&dateEnd=' + dates[1] + '&page=' + page
      }
    }
    return this.get(`${nameSpace}feedback/filter${params}`)
  },
  feedbackUser (userId) {
    return this.get(`${nameSpace}feedback/${userId}`)
  }
  // show (id) {
  //   return this.get(`${nameSpace}bar/${id}`)
  // },
  // editBarMeasure (id, payload) {
  //   return this.put(`${nameSpace}bar/${id}`, payload)
  // }
  // edit (id, payload) {
  //   return this.put(`${nameSpace}/${id}`, payload)
  // }
  // destroy (id) {
  //   return this.delete(`${nameSpace}/${id}`)
  // }
}
