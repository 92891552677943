import { BASE_SERVICE } from '../BaseService'

const nameSpace = '/user-report/'

export const UsersReportService = {
  ...BASE_SERVICE,
  // create (id, payload) {
  //   return this.post(`${nameSpace}${id}`, payload)
  // },
  index (filterState, dateStart, dateEnd) {
    return this.get(`${nameSpace}?usersFilterState=${filterState}&dateStart=${dateStart}&dateEnd=${dateEnd}`)
  }
  // show (barIds, payload, filterState) {
  //   return this.get(`${nameSpace}stats?dateStart=${payload.dateStart}&dateEnd=${payload.dateEnd}&bookingsFilterState=${filterState}&barIds=${barIds}`)
  // }
  // edit (id, payload) {
  //   return this.put(`${nameSpace}${id}`, payload)
  // },
  // postImage (id, payload) {
  //   return this.post(`${nameSpace}image/${id}`, payload)
  // },
  // editState (id, payload) {
  //   return this.put(`${nameSpace}state/${id}`, payload)
  // },
  // searchBar (payload) {
  //   return this.get(`${nameSpace}search/?name=${payload}`)
  // }
  // destroy (id) {
  //   return this.delete(`${nameSpace}/${id}`)
  // }
}
