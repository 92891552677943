import { state } from './companies.state'
import { getters } from './companies.getters'
import { mutations } from './companies.mutations'
import { actions } from './companies.actions'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
