import { BASE_SERVICE } from '../BaseService'

const nameSpace = '/tables/'

export const TablesService = {
  ...BASE_SERVICE,
  create (payload) {
    return this.post(`${nameSpace}`, payload)
  }
  // index (id) {
  //   return this.get(`${nameSpace}${id}`)
  // },
  // show (id) {
  //   return this.get(`${nameSpace}bar/${id}`)
  // }
  // edit (id, payload) {
  //   return this.put(`${nameSpace}member/${id}`, payload)
  // }
  // destroy (id) {
  //   return this.delete(`${nameSpace}/${id}`)
  // }
}
