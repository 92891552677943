import { BASE_SERVICE } from '../BaseService'

const nameSpace = '/bar/'

export const BarsService = {
  ...BASE_SERVICE,
  create (payload) {
    return this.post(`${nameSpace}`, payload)
  },
  index (payload) {
    return this.get(`${nameSpace}?page=${payload}`)
  },
  show (id) {
    return this.get(`${nameSpace}${id}`)
  },
  edit (id, payload) {
    return this.put(`${nameSpace}${id}`, payload)
  },
  postImage (id, payload) {
    return this.post(`${nameSpace}image/${id}`, payload)
  },
  editState (id, payload) {
    return this.put(`${nameSpace}state/${id}`, payload)
  },
  searchBar (payload) {
    return this.get(`${nameSpace}search/?name=${payload}`)
  },
  listBar () {
    return this.get(`${nameSpace}list/`)
  },
  delCarImg (id, payload) {
    return this.put(`${nameSpace}carousel/image/${id}`, payload)
  },
  carOrderImg (id, payload) {
    return this.put(`${nameSpace}carousel/edit/${id}`, payload)
  },
  premiumState (id, payload) {
    return this.put(`${nameSpace}premium/${id}`, payload)
  }
  // destroy (id) {
  //   return this.delete(`${nameSpace}/${id}`)
  // }
}
