import { BASE_SERVICE } from '../BaseService'

const nameSpace = '/business-type/'

export const BusinessTypesService = {
  ...BASE_SERVICE,
  create (payload) {
    return this.post(`${nameSpace}`, payload)
  },
  image (id, payload, type) {
    return this.post(`${nameSpace}${id}?type=${type}`, payload)
  },
  index () {
    return this.get(`${nameSpace}`)
  },
  // show (id) {
  //   return this.get(`${nameSpace}/${id}`)
  // },
  edit (id, payload) {
    return this.put(`${nameSpace}${id}`, payload)
  },
  destroy (id) {
    return this.delete(`${nameSpace}${id}`)
  }
}
