import Vue from 'vue'
import { ServiceFactory } from '@/services/ServiceFactory'
const _barImageService = ServiceFactory.get('BarsService')

export const actions = {
  setCompanyData: (context, barId) => {
    context.commit('SET_COMPANY_DATA', barId)
  },
  async imageProfileUpload ({ commit }, imageUpload) {
    try {
      // const { settings } =
      await _barImageService.postImage(imageUpload)
      // await commit('UPDATE_IMAGE_PROFILE', settings.images.profile)
    } catch (error) {
      Vue.prototype.$notify({
        title: 'Error:',
        type: 'error',
        text: error.msg
      })
    }
  },
  async imageBannerUpload ({ commit }, imageUpload) {
    try {
      // const { settings } =
      await _barImageService.postImage(imageUpload)
      // await commit('UPDATE_IMAGE_BANNER', settings.images.banner)
    } catch (error) {
      Vue.prototype.$notify({
        title: 'Error:',
        type: 'error',
        text: error.msg
      })
    }
  }
}
