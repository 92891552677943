import { BASE_SERVICE } from '../BaseService'
import { buildFormData } from '../ZbBuildFormData.service'

const nameSpace = '/bar/billing/'

export const BillingReportService = {
  ...BASE_SERVICE,
  // create (id, payload) {
  //   return this.post(`${nameSpace}${id}`, payload)
  // },
  index (payload, cityId) {
    return this.get(`${nameSpace}?dateStart=${payload.dateStart}&dateEnd=${payload.dateEnd}&city_id=${cityId}`)
  },
  sendEmail (payload) {
    // return this.get(`${nameSpace}stats?dateStart=${payload.dateStart}&dateEnd=${payload.dateEnd}&bookingsFilterState=${filterState}&barIds=${barIds}`)
    const auxFormData = buildFormData(payload)
    return this.post(`${nameSpace}send/email/`, auxFormData)
  },
  // edit (id, payload) {
  //   return this.put(`${nameSpace}${id}`, payload)
  // },
  generate (payload) {
    return this.post(`${nameSpace}billing-period`, payload)
  },
  periodState (id) {
    return this.put(`${nameSpace}${id}`)
  },
  removeBilling (id, payload) {
    return this.put(`${nameSpace}remove/${id}`, payload)
  },
  generateQr (payload) {
    return this.post(`${nameSpace}payment-qr`, payload)
  }
}
