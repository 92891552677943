export const mutations = {
  INITIALIZE (state, { settings, success }) {
    state.dataSettings.success = success
    state.dataSettings.settings = settings
    state.dataSettings.settings.typeBusiness = settings.typeBusiness
    if (settings.images) {
      state.dataSettings.settings.images = {
        profile: settings.images.profile,
        banner: settings.images.banner
      }
    } else {
      state.dataSettings.settings.images = {
        profile: `${process.env.BASE_URL}images/user-default.png`,
        banner: `${process.env.BASE_URL}images/banner-default.png`
      }
    }
  },
  UPDATE_IMAGE_PROFILE (state, imageProfile) {
    state.dataSettings.settings.images.profile = imageProfile
  },
  UPDATE_IMAGE_BANNER (state, imageBanner) {
    state.dataSettings.settings.images.banner = imageBanner
  },
  UPDATE_SETTINGS (state, data) {
    state.dataSettings.settings = data.settings
  }
}
