import { BASE_SERVICE } from '../BaseService'
import { buildFormData } from '../ZbBuildFormData.service'

const nameSpace = '/bar/video-list'

export const BarVideoService = {
  ...BASE_SERVICE,
  create (payload) {
    const auxFormData = buildFormData(payload)
    return this.post(`${nameSpace}`, auxFormData)
  },
  index (id) {
    return this.get(`${nameSpace}/${id}`)
  },
  // show (id) {
  //   return this.get(`${nameSpace}/${id}`)
  // },
  edit (id, video, payload) {
    const auxFormData = buildFormData(payload)
    return this.put(`${nameSpace}/order/${id}/video/${video}`, auxFormData)
  },
  state (id, video, payload) {
    return this.put(`${nameSpace}/${id}/video/${video}`, payload)
  },
  destroy (id, video) {
    return this.delete(`${nameSpace}/${id}/video/${video}`)
  }
}
