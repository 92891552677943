import { BASE_SERVICE } from '../BaseService'

const nameSpace = '/notifications/'

export const NotificationsService = {
  ...BASE_SERVICE,
  send (payload) {
    return this.post(`${nameSpace}send/`, payload)
  },
  index () {
    return this.get(`${nameSpace}send/`)
  },
  sendOne (payload) {
    return this.post(`${nameSpace}`, payload)
  },
  sendToPeople (payload) {
    return this.post(`${nameSpace}selection`, payload)
  },
  singleNotificationStats (id) {
    return this.get(`${nameSpace}stats/${id}`)
  },
  notificationsStats (dateStart, dateEnd) {
    return this.get(`${nameSpace}stats/?dateStart=${dateStart}&dateEnd=${dateEnd}`)
  }
}
