export const state = {
  dataSettings: {
    success: false,
    settings: {
      zbBarId: '',
      name: '',
      slogan: '',
      maxPeopleCapacity: 20,
      images: {
        profile: `${process.env.BASE_URL}images/user-default.png`,
        banner: `${process.env.BASE_URL}images/banner-default.png`
      },
      typeBusiness: ''
    }
  }
}
