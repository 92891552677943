export const authActions = {
  /**
   * Login action method for get credentials
   * @param commit
   * @param dispatch
   * @param url Url to login
   * @param username username field
   * @param password password field
   */
  storeCredentials: ({ commit, dispatch }, { data }) => {
    console.log(data)
    commit('LOGIN_SUCCESS', data)
  },
  /**
   * Logout action method for remove credential go to login page
   * @param commit
   * @param url
   */
  removeCredentials: ({ commit }) => {
    commit('LOGOUT')
  }
}
