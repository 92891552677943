import { BASE_SERVICE } from '../BaseService'

const nameSpace = '/products-category/'

export const ProductCategoriesService = {
  ...BASE_SERVICE,
  create (payload) {
    return this.post(`${nameSpace}`, payload)
  },
  index (id) {
    return this.get(`${nameSpace}bar/${id}`)
  },
  show (id) {
    return this.get(`${nameSpace}${id}`)
  },
  edit (id, payload) {
    return this.put(`${nameSpace}${id}`, payload)
  },
  searchCategory (id, payload) {
    return this.get(`${nameSpace}search/${id}?name=${payload}`)
  },
  destroy (id) {
    return this.delete(`${nameSpace}${id}`)
  }
}
