import { BASE_SERVICE } from '../BaseService'

const nameSpace = '/feedback/'

export const FeedbackService = {
  ...BASE_SERVICE,
  create (userId, payload) {
    return this.post(`${nameSpace}${userId}`, payload)
  },
  index (userId) {
    return this.get(`${nameSpace}${userId}`)
  }
  // show (id) {
  //   return this.get(`${nameSpace}/${id}`)
  // },
  // edit (id, payload) {
  //   return this.put(`${nameSpace}/${id}`, payload)
  // },
  // destroy (id) {
  //   return this.delete(`${nameSpace}/${id}`)
  // }
}
