import { BASE_SERVICE } from '../BaseService'

const nameSpace = '/bar/event-calendar/'

export const DiscountCalendarService = {
  ...BASE_SERVICE,
  create (payload) {
    return this.post(`${nameSpace}`, payload)
  },
  index () {
    return this.get(`${nameSpace}`)
  },
  show (barId) {
    return this.get(`${nameSpace}events/${barId}`)
  },
  edit (id, payload) {
    return this.put(`${nameSpace}${id}`, payload)
  },
  destroy (id) {
    return this.delete(`${nameSpace}${id}`)
  }
  // postImage (id, payload) {
  //   return this.post(`${nameSpace}image/${id}`, payload)
  // },
  // editState (id, payload) {
  //   return this.put(`${nameSpace}state/${id}`, payload)
  // },
  // searchBar (payload) {
  //   return this.get(`${nameSpace}search/?name=${payload}`)
  // }
}
