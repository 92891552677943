import Vue from 'vue'
import { ServiceFactory } from '@/services/ServiceFactory'
const SettingsService = ServiceFactory.get('SettingsService')

export const actions = {
  setSettings: ({ commit }, { data }) => {
    commit('INITIALIZE', data)
  },
  async imageProfileUpload ({ commit }, imageUpload) {
    try {
      const { settings } = await SettingsService.postImage(imageUpload)
      await commit('UPDATE_IMAGE_PROFILE', settings.images.profile)
    } catch (error) {
      Vue.prototype.$notify({
        title: 'Error:',
        type: 'error',
        text: error.msg
      })
    }
  },
  async imageBannerUpload ({ commit }, imageUpload) {
    try {
      const { settings } = await SettingsService.postImage(imageUpload)
      await commit('UPDATE_IMAGE_BANNER', settings.images.banner)
    } catch (error) {
      Vue.prototype.$notify({
        title: 'Error:',
        type: 'error',
        text: error.msg
      })
    }
  },
  async updateProfile ({ commit }, data) {
    try {
      const res = await SettingsService.put(data)
      await commit('UPDATE_SETTINGS', res)
      Vue.prototype.$notify({
        title: 'Exito:',
        type: 'success',
        text: res.msg
      })
    } catch (error) {
      Vue.prototype.$notify({
        title: 'Error:',
        type: 'error',
        text: error.message
      })
    }
  }
}
