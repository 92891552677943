import { BASE_SERVICE } from '../BaseService'

const nameSpace = '/bar/special-conditions/'

export const SpecialConditionsService = {
  ...BASE_SERVICE,
  create (id, payload) {
    return this.post(`${nameSpace}bar/${id}`, payload)
  },
  // index () {
  //   return this.get(`${nameSpace}`)
  // },
  show (id) {
    return this.get(`${nameSpace}bar/${id}`)
  },
  edit (id, payload) {
    return this.put(`${nameSpace}${id}`, payload)
  },
  // edit (id, payload) {
  //   return this.put(`${nameSpace}/${id}`, payload)
  // }
  destroy (id) {
    return this.delete(`${nameSpace}${id}`)
  }
}
