export const mutations = {
  SET_COMPANY_DATA: (state, barId) => {
    state.companyData.zbBarId = barId
  }
  // UPDATE_IMAGE_PROFILE (state, imageProfile) {
  //   state.dataSettings.settings.images.profile = imageProfile
  // },
  // UPDATE_IMAGE_BANNER (state, imageBanner) {
  //   state.dataSettings.settings.images.banner = imageBanner
  // }
}
