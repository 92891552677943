import { BASE_SERVICE } from '../BaseService'

const nameSpace = '/bookings/'

export const BookingsService = {
  ...BASE_SERVICE,
  create (payload) {
    return this.post(`${nameSpace}`, payload)
  },
  index (id, page, type, items) {
    if (items) {
      return this.get(`${nameSpace}${id}?page=${page}&type=${type}&itemsPerPage=${items}`)
    } else {
      return this.get(`${nameSpace}${id}?page=${page}&type=${type}`)
    }
  },
  getAll (date, cityId) {
    return this.get(`${nameSpace}?dateBooking=${date}&city_id=${cityId}`)
  },
  reviews (id) {
    return this.get(`${nameSpace}reviews/${id}`)
  },
  // show (id) {
  //   return this.get(`${nameSpace}${id}`)
  // },
  // edit (id, payload) {
  //   return this.put(`${nameSpace}${id}`, payload)
  // },
  // postImage (id, payload) {
  //   return this.post(`${nameSpace}image/${id}`, payload)
  // },
  verify (code) {
    return this.put(`${nameSpace}code?code=${code}`)
  },
  people (id, numberPeople) {
    return this.put(`${nameSpace}people/${id}?numberPeople=${numberPeople}`)
  },
  cancel (id, payload) {
    return this.put(`${nameSpace}${id}`, payload)
  },
  // searchBar (payload) {
  //   return this.get(`${nameSpace}search/?name=${payload}`)
  // }
  removePenalty (id) {
    return this.delete(`${nameSpace}penalty/${id}`)
  }
}
