import { BASE_SERVICE } from '../BaseService'

const nameSpace = 'auth'

export const AuthService = {
  ...BASE_SERVICE,
  loginSubmt (data) {
    return this.post(`${nameSpace}/login`, data)
  },
  loginSettings () {
    return this.get(`${nameSpace}/settings`)
  },
  logOutSubmit () {
    // console.info(`${nameSpace} Service`)
    return Promise.resolve(true)
  },
  registerUser (payload) {
    return this.post(`${nameSpace}/register`, payload)
  }
}
