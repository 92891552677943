const theme = {
  themes: {
    dark: {
      primary: {
        base: '#078C03'
      },
      secondary: {
        base: '#1b1a27'
      },
      accent: {
        base: '#D99B66'
      },
      'accent-2': {
        base: '#BF5050'
      },
      text: {
        base: '#fff'
      },
      error: {
        base: '#FB4C4B'
      },
      warning: {
        base: '#FCDC4C'
      },
      success: {
        base: '#00c96f'
      },
      info: {
        base: '#557dc0'
      }
    },
    light: {
      primary: {
        base: '#ff5a5f'
      },
      secondary: {
        base: '#1b1a27'
      },
      accent: {
        base: '#D99B66'
      },
      'accent-2': {
        base: '#BF5050'
      },
      text: {
        base: '#fff'
      },
      error: {
        base: '#FB4C4B'
      },
      warning: {
        base: '#FCDC4C'
      },
      success: {
        base: '#00c96f'
      },
      info: {
        base: '#557dc0'
      }
    }
  }
}

export default theme
