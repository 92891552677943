import { BASE_SERVICE } from '../BaseService'

const nameSpace = '/bar/report/booking/'

export const BookingsReportService = {
  ...BASE_SERVICE,
  // create (id, payload) {
  //   return this.post(`${nameSpace}${id}`, payload)
  // },
  index (id, payload, filterState) {
    return this.get(`${nameSpace}statistics/${id}?dateStart=${payload.dateStart}&dateEnd=${payload.dateEnd}&bookingsFilterState=${filterState}`)
  },
  show (barIds, payload, filterState) {
    return this.get(`${nameSpace}stats?dateStart=${payload.dateStart}&dateEnd=${payload.dateEnd}&bookingsFilterState=${filterState}&barIds=${barIds}`)
  }
  // edit (id, payload) {
  //   return this.put(`${nameSpace}${id}`, payload)
  // },
  // postImage (id, payload) {
  //   return this.post(`${nameSpace}image/${id}`, payload)
  // },
  // editState (id, payload) {
  //   return this.put(`${nameSpace}state/${id}`, payload)
  // },
  // searchBar (payload) {
  //   return this.get(`${nameSpace}search/?name=${payload}`)
  // }
  // destroy (id) {
  //   return this.delete(`${nameSpace}/${id}`)
  // }
}
