import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import zoundbarApi from './api'
import vuetify from './plugins/vuetify/'
import tiptapvuetify from './plugins/tiptap-vuetify'
import vueyoutube from './plugins/vueyoutube'
import vuefullscreen from './plugins/vuefullscreen'
import vuelidate from './plugins/vuelidate'
import veevalidate from './plugins/veevalidate'
import vuecroppie from './plugins/vuecroppie'
import sweetalert from './plugins/sweetalert'
import vuechatscroll from './plugins/vuechatscroll'
import './plugins/nandes-kit'
import 'croppie/croppie.css'
import './shared/components/'
import i18n from './plugins/i18n'
import vuenotification from './plugins/vuenotification'
import 'chart.js'
import 'hchs-vue-charts'

Vue.use(window.VueCharts)
Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  store,
  vuetify,
  vuelidate,
  sweetalert,
  vuecroppie,
  vueyoutube,
  veevalidate,
  vuechatscroll,
  tiptapvuetify,
  vuefullscreen,
  vuenotification,
  zoundbarApi,
  render: h => h(App)
}).$mount('#app')
