export default {
  inserted: function (el, binding, vnode) {
    // console.log(vnode.context.$store.state.authModule.data.user.modules)
    // console.log(vnode)
    const rolesArray = vnode.context.$store.state.authModule.data.user.modules.map((v, k) => {
      return v.module
    })
    if (!rolesArray.includes(binding.value)) {
      vnode.elm.parentElement.removeChild(vnode.elm)
    }
  }
}
